import $ from "jquery"

export default function() {

    const $navToggleBtn = $(".navToggle .hamburger");
    const $primaryNavOverlay = $(".primaryNavOverlay");

    $navToggleBtn.on("click", function() {
        console.log("TEST")
        $navToggleBtn.toggleClass("is-active");
        $primaryNavOverlay.slideToggle();
        $primaryNavOverlay.toggleClass("is-active");
    })

}