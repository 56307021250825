import $ from "jquery"

export default function() {

    const flag = $(".flag");

    flag.on("click", function() {
        $("#ansprechpartner .accordion-content").slideDown();
    })

}