import $ from "jquery"

export default function() {


    /**
     * Default smooth scroll
     */
    // Select all links with hashes
    $('a[href*="#"]')
        // Remove links that don't actually link to anything
        .not('[href="#"]')
        .not('[href="#0"]')
        .click(function(event) {
            // On-page links
            if (
                location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
                location.hostname == this.hostname
            ) {
                // Figure out element to scroll to
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                // Does a scroll target exist?
                if (target.length) {
                    // Only prevent default if animation is actually gonna happen
                    event.preventDefault();
                    $('html, body').animate({
                        scrollTop: target.offset().top
                    }, 1000, function() {
                        // Callback after animation
                        // Must change focus!
                        var $target = $(target);
                        $target.focus();
                        if ($target.is(":focus")) { // Checking if the target was focused
                            return false;
                        } else {
                            $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                        };
                    });
                }
            }
        });



    /**
     * Additional fix for Firefox
     */
    function pgshow(e) {
        console.log("FF FIX")
        let elId = window.location.hash;
        let el;
        if (elId.length > 1) {
            el = document.getElementById(elId.substr(1));
            if (el) el.scrollIntoView(true);
        }
    }
    // pageshow fires after load and on Back/Forward
    window.addEventListener('pageshow', pgshow);




}