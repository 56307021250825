// require("mmenu-js/dist/mmenu.css")
require("./scss/index.scss")

import carousel from "./js/modules/carousel";
import scrollEvents from "./js/modules/scrollEvents";
import topLinks from "./js/modules/topLink";
import accordion from "./js/modules/accordion";
import mapsSelect from "./js/modules/mapsSelect";
import header from "./js/modules/header";
import flag from "./js/modules/flag";
import smoothScroll from "./js/modules/smoothScroll";
import customSelect from "./js/modules/customSelect";
import employeeFilter from "./js/modules/employeeFilter";

(function() {
    header();
    carousel();
    scrollEvents();
    topLinks();
    accordion();
    mapsSelect();
    flag();
    smoothScroll();
    customSelect();
    employeeFilter();
})();