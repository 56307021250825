export default function() {


    const mapSelect = document.querySelector(".custom-select");
    const mapAllLocations = document.getElementById("mapAllLocations");
    const mapElectronics = document.getElementById("mapElectronics");
    const mapHydraulics = document.getElementById("mapHydraulics");
    const mapAirConditioning = document.getElementById("mapAirConditioning");

    const locationsHydraulics = document.querySelectorAll(".hydraulik")
    const locationsElectronics = document.querySelectorAll(".elektronik")
    const locationsAirCond = document.querySelectorAll(".klima")


    if (mapSelect) {
        mapSelect.addEventListener("click", function(e) {

            let selectedName = document.querySelector(".select-selected").innerHTML

            switch (selectedName) {
                case "Alle":
                case "All":
                    mapAllLocations.style.display = "block";
                    mapHydraulics.style.display = "none";
                    mapAirConditioning.style.display = "none";
                    mapElectronics.style.display = "none";
                    showSections(locationsAirCond, locationsHydraulics, locationsElectronics)
                    break;

                case "Elektronik":
                case "Electronics":
                    mapAllLocations.style.display = "none";
                    mapHydraulics.style.display = "none";
                    mapAirConditioning.style.display = "none";
                    mapElectronics.style.display = "block";
                    hideSections(locationsAirCond, locationsHydraulics)
                    showSections(locationsElectronics)
                    break;

                case "Hydraulik":
                case "Hydraulics":
                    mapAllLocations.style.display = "none";
                    mapAirConditioning.style.display = "none";
                    mapElectronics.style.display = "none";
                    mapHydraulics.style.display = "block";
                    hideSections(locationsElectronics, locationsAirCond)
                    showSections(locationsHydraulics)
                    break;

                case "Klima":
                case "Air-conditioning":
                    mapAllLocations.style.display = "none";
                    mapElectronics.style.display = "none";
                    mapHydraulics.style.display = "none";
                    mapAirConditioning.style.display = "block";
                    hideSections(locationsElectronics, locationsHydraulics)
                    showSections(locationsAirCond)
                    break;

            }
        })
    }

    function hideSections(...args) {
        args.forEach(arg => {
            arg.forEach(section => {
                section.style.display = "none";
            })
        })
    }

    function showSections(...args) {
        args.forEach(arg => {
            arg.forEach(section => {
                section.style.display = "flex";
            })
        })
    }


}