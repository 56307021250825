import $ from "jquery"
import slick from "slick-carousel"

export default function() {


    $(document).ready(function() {

        const sliderStage = $(".slick-slider-stage")
        const slider = $(".slick-slider-featured-products")
        const sliderMilestones = $(".slick-slider-milestones")
        const sliderProductImages = $(".slick-slider-produktbilder")
        const sliderAccessoires = $(".slick-slider-zubehoer")
        const sliderStage2021Home = $(".stage-2021-home .slick-slider")
        const sliderImageGallery = $(".slick-slider-image-gallery")

        if (sliderStage) {
            sliderStage.slick({
                dots: true,
                arrows: false,
                slidesToShow: 1,
                autoplay: true,
                autoplaySpeed: 4000
            })
        }

        if (sliderMilestones) {
            sliderMilestones.slick({
                dots: true,
                arrows: false,
                slidesToShow: 1,
            })
        }

        if (sliderProductImages) {
            sliderProductImages.slick({
                dots: true,
                arrows: false,
                slidesToShow: 2,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 4000,
                responsive: [{
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }, ]
            })
        }

        if (sliderAccessoires) {
            sliderAccessoires.slick({
                slidesToShow: 5,
                slidesToScroll: 1,
                responsive: [{
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }, ]
            })
        }

        if (sliderStage2021Home) {
            sliderStage2021Home.slick({
                dots: true,
                arrows: false,
                slidesToShow: 3,
                autoplay: true,
                autoplaySpeed: 1000,
                centerMode: true,
                variableWidth: true,
                variableHeight: true,
                responsive: [{
                    breakpoint: 769,
                    settings: {
                        slidesToShow: 1
                    }
                }]
            })
        }

        if (sliderImageGallery) {
            sliderImageGallery.slick({
                dots: true,
                arrows: false,
                slidesToShow: 3,
                autoplay: true,
                autoplaySpeed: 4000,
                centerMode: true,
                centerPadding: '60px',

            })
        }




        if (slider != null) {
            slider.slick({
                dots: true,
                arrows: false,
                slidesToShow: 1,

            })


            for (let i = 0; i < slider.length; i++) {

                if (slider[i].classList.contains("slick-slider-zubehoer")) {

                    $('.slick-slider-zubehoer.slick-initialized').slick('slickSetOption', {
                        slidesToShow: 5,
                        slidesToScroll: 1,
                        responsive: [{
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }, ]
                    }, true);
                }
                if (
                    slider[i].classList.contains("slick-slider-produktbilder") ||
                    slider[i].classList.contains("slick-slider-featured-products") ||
                    slider[i].classList.contains("slick-slider-team")) {
                    $('.slick-slider-produktbilder.slick-initialized, .slick-slider-featured-products.slick-initialized, .slick-slider-team.slick-initialized').slick('slickSetOption', {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        adaptiveHeight: true,
                        responsive: [{
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }, ]
                    }, true);
                }
                if (slider[i].classList.contains("slick-slider-team")) {
                    $('.slick-slider-produktbilder.slick-initialized, .slick-slider-team.slick-initialized').slick('slickSetOption', {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        responsive: [{
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }, ]
                    }, true);
                }
                if (slider[i].classList.contains("slick-slider-milestones")) {
                    $('.slick-slider-milestones.slick-initialized').slick('slickSetOption', {
                        arrows: false
                    }, true)
                }
            }





        } else {
            console.log(slider)
            console.info("No slider container!")
        }


        $(".slick-slider-team").slick({
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: false,
            dots: true,
            responsive: [{
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }],

        })


        $(".teamList .slick-slider-team .contactPerson").each(function() {
            console.log("TEST")
            let classes = $(this).attr("class").replace('contactPerson', '');
            $(this).parents(".slick-slide").addClass(classes)
        })


        $('.select-items').on('click', function(e) {
            let attributes = e.target.attributes
            console.log("==========")
            console.log(attributes)
            console.log("==========")

            // let filterValue
            // for (let i = 0; i < attributes.length; i++) {
            //     if (attributes[i].name === "value") {
            //         filterValue = attributes[i].nodeValue
            //         console.log(filterValue)
            //     }
            // }


            let filterValue = document.body.classList.contains("Edge") ? e.target.attributes[1].nodeValue : e.target.attributes[0].nodeValue
            console.log(filterValue)
            if (filterValue === "all") {
                $('.teamList .slick-slider-team').slick('slickUnfilter');
            } else {
                $('.teamList  .slick-slider-team').slick('slickUnfilter').slick('slickFilter', `.${filterValue}`)
            }
        })


    })

}