import $ from "jquery"
const WOW = require('wowjs');

import ScrollMagic from 'ScrollMagic'
import 'animation.gsap'
import 'debug.addIndicators'
import { pinSection, blurBg, inView } from './scrollmagic-helper.js';
import counterup from "counterup2"

export default function() {

    window.wow = new WOW.WOW({
        live: false
    });

    window.wow.init();

    const controller = new ScrollMagic.Controller();

    console.log("Hello from Scroll Events!")

    $(".aos").each(function() {
        const scene = new ScrollMagic.Scene({
                triggerElement: this,
                triggerHook: "onEnter",
                offset: "200"
            })
            .setClassToggle(this, "is-visible")
    })


    const $counters = $('.count')
    const $locations = $("#locations")

    if ($counters.length > 0 && $locations.length) {
        const locationsScene = new ScrollMagic.Scene({
                triggerElement: "#locations",
                triggerHook: "onEnter",
                offset: "200"
            })
            .addTo(controller);

        locationsScene.on("enter", function() {
            $counters.each(function(ignore, counter) {
                counterup(counter, {
                    duration: 1000,
                    delay: 16
                });
            });
        })
    }

}